import * as sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default {
  init: (dsn: string | undefined) => {
    sentry.init({
      dsn,
      integrations: [new BrowserTracing()],
      ignoreErrors: ['code 401', 'timeout', 'code 422'],
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV,
    });
  },
};
