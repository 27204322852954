import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Grid, Typography, useTheme } from '@dripcapital/dripui';
import { FONT } from 'utils';
import { Spacer } from 'atoms';

type Props = {
  bankName?: string;
  bankFedABANumber?: string;
  accountNumber?: string;
  bankCurrency?: string;
  bankAddress?: string;
  accountHolderName?: string;
  accountHolderAddress?: string;
};

const ACHBankDetailsCard: React.FC<Props> = ({
  bankName,
  bankFedABANumber,
  accountNumber,
  bankCurrency,
  bankAddress,
  accountHolderName,
  accountHolderAddress,
}: Props) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const renderDetails = () => {
    const bankDetails = [
      {
        label: t('usoa_ach_bank_name'),
        value: bankName,
      },
      {
        label: t('usoa_ach_fed_aba'),
        value: bankFedABANumber,
      },
      {
        label: t('usoa_ach_account_number'),
        value: accountNumber,
      },
      {
        label: t('usoa_ach_bank_currency'),
        value: bankCurrency,
      },
      {
        label: t('usoa_bd_subheading_2'),
        value: bankAddress,
      },
    ];
    const holdersBankDetails = [
      {
        label: t('usoa_ach_holder_label'),
        value: accountHolderName,
      },
      {
        label: t('usoa_ach_holder_address_label'),
        value: accountHolderAddress,
      },
    ];

    return (
      <>
        <Typography variant="body1">{t('usoa_bd_subheading')}</Typography>
        <Spacer verticalSpace={1} />
        {bankDetails?.map((data, index) => {
          return (
            <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ minWidth: spacing(27.5) }}
                  noWrap
                >
                  {data.label}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  _sx={{ fontSize: FONT.SIZES.F16 }}
                >
                  {data?.value?.trim() || '--'}
                </Typography>
              </Flex>
            </Grid>
          );
        })}
        <Spacer verticalSpace={1} />
        <Typography variant="body1">{t('usoa_bd_subheading_3')}</Typography>
        <Spacer verticalSpace={1} />
        {holdersBankDetails?.map((data, index) => {
          return (
            <Grid key={index} xs={12} item _sx={{ paddingBottom: 2 }}>
              <Flex>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  _sx={{ minWidth: spacing(27.5) }}
                  noWrap
                >
                  {data.label}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="regular"
                  _sx={{ fontSize: FONT.SIZES.F16 }}
                >
                  {data?.value?.trim() || '--'}
                </Typography>
              </Flex>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Box dataTestId="company-info-section">
      <Grid container>{renderDetails()}</Grid>
    </Box>
  );
};

export default ACHBankDetailsCard;
