import * as Yup from 'yup';
import { FILE_SIZE } from './constants';
import { formatDateInput } from './formatters';
import validations from './validations';

const ignoreSpecials = /^[A-Za-z\s0-9]+$/;

const validCompanyName = /^[A-Za-z\s0-9&.]+$/;

const validName = /^[A-Za-z][A-Za-z\s0-9&.]+$/;

const validAddress = /^[A-Za-z\s0-9&\\,\\.\-\\/]+$/;

const hasDigits = /^\d+$/;

const isPANNumber = /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/;

const validPincode = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

const dunsRegex = /^[0-9]{9,9}$/;

const ssnDigits = /^\d{9}$/;

interface CountryStateMap {
  [countryName: string]: string[];
}

const websiteRegex =
  /^(http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(([0-9]{1,5})?\/.*)?$/;

export const emailRegex =
  /(?=^.{1,80}$)^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const profileSchema = Yup.object().shape({
  country: Yup.string().required('profileSchema_country'),
  profileType: Yup.string().required('profileSchema_profileType'),
  jobTitle: Yup.string()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .min(3, 'profileSchema_jobTitle_min')
    .required('profileSchema_jobTitle'),
});

export const signUpSchema = Yup.object().shape({
  name: Yup.string()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('signUpSchema_name'),
  email: Yup.string()
    .email('signUpSchema_valid_email')
    .required('signUpSchema_email'),
  cc: Yup.string().required(),
  phone: Yup.number()
    .required('bfs_contact_number')
    .test('len', 'bfs_correct_phone', (value, { parent }) => {
      return validations.isPhoneNumberValid(value, parent.cc);
    })
    .typeError('bds_pincode_number'),
  password: Yup.string()
    .min(8, 'password_min')
    .max(14, 'password_max')
    .matches(/^(\S+$)/, 'password_no_space')
    .matches(/(?=.*[a-z])/, 'password_lowercase')
    .matches(/(?=.*[A-Z])/, 'password_uppercase')
    .matches(/(?=.*\d)/, 'password_number')
    .matches(/(?=.*\W)/, 'password_character')
    .required('password_required'),
  confirm_password: Yup.string()
    .required('password_required')
    .oneOf([Yup.ref('password')], 'password_match'),
});

export const newPasswordSchema = signUpSchema.pick([
  'password',
  'confirm_password',
]);

export const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'password_min')
    .max(14, 'password_max')
    .matches(/(?=.*[a-z])/, 'password_lowercase')
    .matches(/(?=.*[A-Z])/, 'password_uppercase')
    .matches(/(?=.*\d)/, 'password_number')
    .matches(/(?=.*\W)/, 'password_character')
    .required('password_required'),
});

export const businessDetailsSchema = Yup.object().shape({
  companyName: Yup.string()
    .matches(validCompanyName, 'bfs_no_specials')
    .required('bds_companyName'),
  industry: Yup.string()
    .matches(validCompanyName, 'bfs_no_specials')
    .required('bds_industry'),
  companyAddress: Yup.string()
    .matches(validAddress, 'bfs_no_specials')
    .min(5, 'bds_companyAddress_min')
    .required('bds_companyAddress'),
  country: Yup.string().required('bds_country'),
  region_state: Yup.string().required('bds_region_state'),
});

export const scfPincodeSchema = Yup.object().shape({
  pincode: Yup.string()
    .matches(validPincode, 'abs_pincode')
    .required('sbes_pincode'),
});

export const rfBusinessEligibilitySchema = Yup.object().shape({
  goodsType: Yup.string()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('bes_goodsType'),
  productCategory: Yup.string()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('bds_industry'),
});

// here we are extending the above schema below as both the above fields are common.
export const scfBusinessEligibilitySchema = rfBusinessEligibilitySchema.shape({
  lastTwelveMonthsRevenue: Yup.string().required('lastTwelveMonthsRevenue'),
  lastTwelveMonthsShipment: Yup.number()
    .min(10, 'lastTwelveMonthsShipment_min')
    .required('lastTwelveMonthsShipment'),
});

export const dunsIdentifierSchema = Yup.object().shape({
  businessIdentifier: Yup.string()
    .matches(dunsRegex, 'invalid_duns_number')
    .required('duns_required'),
});

export const businessIdentifierSchema = Yup.object().shape({
  businessIdentifier: Yup.string().required('businessIdentifier'),
});

export const scfBusinessIdentifierSchema = Yup.object().shape({
  businessIdentifier: Yup.string().required('businessIdentifier'),
});

export const inBuyerOptionalSchema = Yup.object().shape(
  {
    idType: Yup.string()
      .trim()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .when('idNumber', {
        is: (val: string) => {
          return val;
        },
        then: Yup.string().required('bfs_text'),
        otherwise: Yup.string().optional().nullable(),
      }),
    idNumber: Yup.string()
      .trim()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .when('idType', {
        is: (val: string) => {
          return val;
        },
        then: Yup.string().required('bfs_text'),
        otherwise: Yup.string().optional().nullable(),
      }),
  },
  [['idNumber', 'idType']]
);

export const inBuyerFormSchema = Yup.object()
  .shape({
    name: Yup.string()
      .matches(validCompanyName, 'bfs_no_specials')
      .trim()
      .required('bfs_company_name'),
    address: Yup.string()
      .matches(validAddress, 'bfs_no_specials')
      .trim()
      .required('bfs_company_address'),
    country: Yup.string().required('bfs_country'),
    paymentTerms: Yup.string().required('bfs_option'),
    currency: Yup.string().required('bfs_option'),
    paymentDays: Yup.string()
      .matches(hasDigits, 'bds_pincode_number')
      .test('min-number', 'bfs_daysForBL_min', (value) => {
        return parseInt(value || '0') >= 1;
      })
      .test('max-number', 'bfs_daysForBL_max', (value) => {
        return parseInt(value || '0') <= 150;
      })
      .required('bfs_daysForBL'),
    documentSendType: Yup.string().required('bfs_option'),
    documentReleaseType: Yup.string().required('bfs_option'),
    canSubmitAllBill: Yup.string().required('bfs_option'),
    paymentTenor: Yup.string().required('bfs_option'),
    hasInvoice: Yup.string().required('bfs_option'),
    dripLimit: Yup.string()
      .matches(hasDigits, 'bfs_offered_limit_number')
      .test('min-number', 'bfs_offered_limit_min', (value) => {
        return parseInt(value || '0') > 0;
      })
      .test('max-number', 'bfs_offered_limit_max', (value) => {
        return parseInt(value || '0') < 1000000000;
      })
      .required('bfs_offered_limit'),
  })
  .concat(inBuyerOptionalSchema);

export const inOptionalContactInfoSchema = Yup.object().shape({
  contactName: Yup.string().trim().matches(ignoreSpecials, 'bfs_no_specials'),
  contactEmail: Yup.string().trim().email('signUpSchema_valid_email'),
  contactPhoneNumber: Yup.object().shape({
    countryCode: Yup.string(),
    phoneNumber: Yup.number().test(
      'len',
      'bfs_correct_phone',
      (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      }
    ),
  }),
});

export const inContactInfoSchema = Yup.object().shape({
  contactName: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('bfs_contact_name'),
  contactEmail: Yup.string()
    .trim()
    .email('signUpSchema_valid_email')
    .required('bfs_contact_email'),
  contactPhoneNumber: Yup.object().shape({
    countryCode: Yup.string().required('bfs_cc'),
    phoneNumber: Yup.number()
      .required('bfs_contact_number_mandatory')
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
});

export const inBuyerAddFormSchema = Yup.object()
  .concat(inBuyerFormSchema)
  .concat(inOptionalContactInfoSchema);

export const inBuyerUpdateFormSchema = Yup.object().concat(inBuyerFormSchema);

export const mxBuyerFormSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .matches(validName, 'bfs_valid_name')
    .min(3, 'bfs_name_length')
    .max(70, 'bfs_name_length')
    .required('bfs_company_name'),
  address: Yup.string()
    .trim()
    .matches(validAddress, 'bfs_no_specials')
    .min(3, 'bfs_address_length')
    .max(70, 'bfs_address_length')
    .required('bfs_company_address'),
  country: Yup.string().required('bfs_country'),
  rfc: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .min(12, 'sis_rfc_length')
    .max(13, 'sis_rfc_length')
    .required('bfs_rfc'),
  currency: Yup.string().required('bfs_option'),
  paymentDays: Yup.string().required('bfs_option'),
  paymentTenor: Yup.string().required('bfs_option'),
  dripLimit: Yup.string()
    .matches(hasDigits, 'bfs_offered_limit_number')
    .test('min-number', 'bfs_offered_limit_min', (value) => {
      return parseInt(value || '0') > 0;
    })
    .test('max-number', 'bfs_offered_limit_max', (value) => {
      return parseInt(value || '0') < 1000000000;
    })
    .required('bfs_offered_limit'),
  hasInvoice: Yup.string().required('bfs_option'),
});

export const mxOptionalContactInfoSchema = Yup.object().shape({
  contactName: Yup.string()
    .trim()
    .matches(validName, 'bfs_valid_name')
    .min(3, 'bfs_name_length')
    .max(70, 'bfs_name_length'),
  contactEmail: Yup.string().email('abs_companyEmail_valid').trim(),
  contactPhoneNumber: Yup.object().shape({
    countryCode: Yup.string(),
    phoneNumber: Yup.number()
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
});

export const mxContactInfoSchema = Yup.object().shape({
  contactName: Yup.string()
    .trim()
    .matches(validName, 'bfs_valid_name')
    // .matches(ignoreSpecials, 'bfs_no_specials')
    .min(3, 'bfs_name_length')
    .max(70, 'bfs_name_length')
    .required('bfs_contact_name'),
  contactEmail: Yup.string()
    .email('abs_companyEmail_valid')
    .trim()
    .required('bfs_contact_email'),
  contactPhoneNumber: Yup.object().shape({
    countryCode: Yup.string().required('bfs_cc'),
    phoneNumber: Yup.number()
      .required('bfs_contact_number_mandatory')
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
});

export const mxBuyerAddFormSchema = Yup.object()
  .concat(mxBuyerFormSchema)
  .concat(mxOptionalContactInfoSchema);

export const mxBuyerUpdateFormSchema = Yup.object().concat(mxBuyerFormSchema);

export const atomsSchema = Yup.object().shape({
  uploadFinancialStatement: Yup.mixed()
    .required('as_upload')
    .test('fileSize', 'as_size', (value) => {
      if (!value) return false;
      return value.size <= 1024 * 51200; // in bytes (50 mb i.e 1024 kb * 1024 bytes)
    }),
  formDate: Yup.mixed()
    .required('as_empty')
    .test('maxDate', 'as_dateMessage', (value) => {
      if (!value) return false;
      return new Date(formatDateInput(value)) < new Date();
    }),
});

export const shareholderInfoSchema = Yup.object().shape({
  shareholderName: Yup.string()
    .trim()
    .matches(validName, 'bfs_valid_name')
    .min(2, 'sis_shareholder_name_length')
    .max(40, 'sis_shareholder_name_length')
    // .matches(ignoreSpecials, 'bfs_no_specials')
    .required('sis_shareholderName'),
  dob: Yup.mixed()
    .required('sis_formDate')
    .test('maxDate', 'sis_dob', (value) => {
      if (!value) return false;
      return new Date(formatDateInput(value)) < new Date();
    }),
  // gender: Yup.string().required('sis_gender'),
});

export const shareholderInfoSchemaIN = Yup.object()
  .shape({
    identificationCardNumber: Yup.string()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .matches(isPANNumber, 'sis_pan_format')
      .trim()
      .required('sis_pan'),
  })
  .concat(shareholderInfoSchema);

export const shareholderInfoSchemaMX = Yup.object()
  .shape({
    identificationCardNumber: Yup.string()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .trim()
      .min(12, 'sis_rfc_length')
      .max(13, 'sis_rfc_length')
      .required('sis_rfc'),
  })
  .concat(shareholderInfoSchema);

export const shareholderFinancialInfoSchema = Yup.object().shape({
  gender: Yup.string().required('sfis_gender'),
  rfc: Yup.string()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('sfis_rfc'),
  name: Yup.object()
    .shape({
      firstname: Yup.string()
        .trim()
        .matches(validName, 'bfs_valid_name')
        // .matches(ignoreSpecials, 'bfs_no_specials')
        .min(2, 'sfis_name_length')
        .max(40, 'sfis_name_length')
        .required('sfis_name'),
      middlename: Yup.string()
        .trim()
        .matches(validName, 'bfs_valid_name')
        // .matches(ignoreSpecials, 'bfs_no_specials')
        .min(2, 'sfis_name_length')
        .max(40, 'sfis_name_length')
        .required('sfis_lastname1'),
      lastname: Yup.string()
        .trim()
        .matches(validName, 'bfs_valid_name')
        // .matches(ignoreSpecials, 'bfs_no_specials')
        .min(2, 'sfis_name_length')
        .max(40, 'sfis_name_length')
        .required('sfis_lastname2'),
    })
    .default({}),
  email: Yup.string()
    .email('abs_companyEmail_valid')
    .trim()
    .required('sfis_email'),
  address: Yup.object()
    .shape({
      city: Yup.string()
        .trim()
        .matches(validName, 'sfis_invalid_city')
        .min(3, 'sfis_city_length')
        .max(40, 'sfis_city_length')
        .required('sfis_city'),
      country: Yup.string().required('sfis_country'),
      municipality: Yup.string()
        .trim()
        .min(3, 'sfis_municipality_length')
        .max(40, 'sfis_municipality_length')
        .required('sfis_municipality'),
      state: Yup.string().required('sfis_state'),
      street: Yup.string()
        .trim()
        .matches(validAddress, 'bfs_no_specials')
        .min(2, 'sfis_street_length')
        .max(60, 'sfis_street_length')
        .required('sfis_street'),
      zipcode: Yup.number()
        .test(
          'len',
          'sfis_zipcode_length',
          (val) => val?.toString().length === 5
        )
        .typeError('sfis_zipcode_type')
        .required('sfis_zipcode'),
    })
    .default({}),
});

export const companyFinancialInfoSchema = shareholderFinancialInfoSchema
  .shape({
    companyName: Yup.string()
      .trim()
      .matches(validCompanyName, 'bfs_no_specials')
      .min(2, 'sfis_company_name_length')
      .max(60, 'sfis_company_name_length')
      .required('sfis_companyName'),
  })
  .omit(['name', 'gender']);

export const createUserSchema = Yup.object().shape({
  customerFirstName: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('abs_customerFName'),
  customerLastName: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('abs_customerLName'),
  companyEmail: Yup.string()
    .trim()
    .email('abs_companyEmail_valid')
    .required('abs_companyEmail'),
  phone: Yup.number()
    .required('bfs_contact_number')
    .test('len', 'bfs_correct_phone', (value, { parent }) => {
      return validations.isPhoneNumberValid(value, parent.cc);
    })
    .typeError('bds_pincode_number'),
  cc: Yup.string().required('abs_cc'),
  jobTitle: Yup.string().trim().default(''),
});

export const companyRegistrationSchema = (countries: CountryStateMap) =>
  Yup.object().shape({
    businessIdentifier: Yup.string().matches(dunsRegex, 'invalid_duns_number'),
    goodsType: Yup.string()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .required('bes_goodsType'),
    productCategory: Yup.string()
      .matches(ignoreSpecials, 'bfs_no_specials')
      .required('bds_industry'),
    companyName: Yup.string()
      .matches(validCompanyName, 'bfs_no_specials')
      .required('bds_companyName'),
    companyAddress: Yup.string()
      .matches(validAddress, 'bfs_no_specials')
      .min(5, 'bds_companyAddress_min')
      .required('bds_companyAddress'),
    country: Yup.string().required('bds_country'),
    region_state: Yup.string()
      .required()
      .test('state-match', 'bds_accHolderState_match', function (value) {
        const { country } = this.parent; // Access the selected country from the form
        const validStates = countries[country] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (!value) {
          return false;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }),
    pincode: Yup.string()
      .max(20, 'bds_pincode_length')
      .test('countrySpecificRequired', 'sbes_pincode', (value, context) => {
        return validations.isPincodeRequired(value, context);
      })
      .test('countryFormat', 'abs_pincode', (value, context) => {
        return validations.isPincodeValid(value, context);
      }),
  });

export const fileUploadSchema = Yup.object().shape({
  uploadFinancialStatement: Yup.mixed()
    .required('as_upload')
    .test('fileSize', 'as_size', (value) => {
      if (!value) return false;
      return value[0].size <= FILE_SIZE; // in bytes (1 mb i.e 1024 kb * 1024 bytes)
    }),
});

export const personalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('pds_first_name'),
  middleName: Yup.string().max(1, 'pds_middle_name_limit').trim().default(''),
  lastName: Yup.string()
    .trim()
    .matches(ignoreSpecials, 'bfs_no_specials')
    .required('pds_last_name'),
  phone: Yup.object().shape({
    countryCode: Yup.string().required('bfs_cc'),
    phoneNumber: Yup.number()
      .required('bfs_contact_number')
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
  dob: Yup.date()
    .typeError('pds_dob_valid')
    .max(new Date(), 'pds_min_date')
    .required('pds_required'),
  citizenship: Yup.string().required('bds_country'),
  ssn: Yup.string().trim().matches(ssnDigits, 'pds_ssn'),
  consent: Yup.boolean().oneOf([true], 'usoa_checkbox_required'),
});

export const personalDetailsOptionalSchema = Yup.object().shape({
  firstName: Yup.string().trim().matches(ignoreSpecials, 'bfs_no_specials'),
  middleName: Yup.string().max(1, 'pds_middle_name_limit').trim().default(''),
  lastName: Yup.string().trim().matches(ignoreSpecials, 'bfs_no_specials'),
  phone: Yup.object().shape({
    countryCode: Yup.string(),
    phoneNumber: Yup.number()
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
  dob: Yup.string().nullable(),
  citizenship: Yup.string(),
  ssn: Yup.string().trim().matches(ssnDigits, 'pds_ssn'),
  consent: Yup.boolean(),
});

export const scfOwnerSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('css_firstname')
    .matches(validName, 'css_firstname_invalid'),
  lastname: Yup.string()
    .required('css_lastname')
    .matches(validName, 'css_lastname_invalid'),
  phone: Yup.object().shape({
    countryCode: Yup.string().required('bfs_cc'),
    phoneNumber: Yup.number()
      .required('bfs_contact_number')
      .test('len', 'bfs_correct_phone', (value, { parent }) => {
        return validations.isPhoneNumberValid(value, parent.countryCode);
      })
      .typeError('bds_pincode_number'),
  }),
  email: Yup.string().required('css_email'),
  ownershipPercentage: Yup.number()
    .required('css_ownsership')
    .min(0, 'css_min_ownership')
    .max(100, 'css_max_ownership'),
});

export const companyDetailsSchema = (countries: CountryStateMap) =>
  Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .matches(validCompanyName, 'bfs_no_specials')
      .required('cds_company_name'),
    industry: Yup.string().trim().required('cds_industry'),
    incorporationYear: Yup.string()
      .required('cds_year_of_corporation_required')
      .matches(hasDigits, 'cds_year_of_corporation_digit')
      .length(4, 'cds_year_of_corporation_valid')
      .test('year', 'cds_year_of_corporation_range', (value) => {
        if (!value) return false;
        if (+value < 1920 || +value > new Date().getFullYear()) return false;
        return true;
      }),
    country: Yup.string().required('bds_country'),
    physicalCountry: Yup.string().required('bds_country'),
    street: Yup.string().required('pds_street'),
    physicalStreet: Yup.string().required('pds_street'),
    state: Yup.string()
      .required()
      .test('state-match', 'bds_accHolderState_match', function (value) {
        const { country } = this.parent; // Access the selected country from the form
        const validStates = countries[country] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (!value) {
          return false;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }),
    physicalState: Yup.string()
      .required('State/region is required')
      .test('state-match', 'bds_accHolderState_match', function (value) {
        const { physicalCountry } = this.parent; // Access the selected country from the form
        const validStates = countries[physicalCountry] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (!value) {
          return false;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }),
    city: Yup.string().required('pds_city'),
    physicalCity: Yup.string().required('pds_city'),
    zipcode: Yup.string()
      .max(20, 'bds_pincode_length')
      .test('countrySpecificRequired', 'sbes_pincode', (value, context) => {
        return validations.isPincodeRequired(value, context);
      })
      .test('countryFormat', 'abs_pincode', (value, context) => {
        return validations.isPincodeValid(value, context);
      }),
    physicalZipcode: Yup.string()
      .max(20, 'bds_pincode_length')
      .test('countrySpecificRequired', 'sbes_pincode', (value, context) => {
        return validations.isPincodeRequired(value, context);
      })
      .test('countryFormat', 'abs_pincode', (value, context) => {
        return validations.isPincodeValid(value, context);
      }),
    dba: Yup.string(),
    companyWebsite: Yup.string().trim().matches(websiteRegex, {
      message: 'bds_website_invalid',
    }),
    eCommerceWebsiteRef: Yup.boolean().test(
      'isRequired',
      'css_e_comm_option',
      (value: any) => {
        if ([true, false].includes(value)) {
          return true;
        }
        return false;
      }
    ),
    ecomSellerLink: Yup.string()
      .trim()
      .test('isRequired', 'e_comm_website_url', (value: any, context) => {
        if (context.parent.eCommerceWebsiteRef) {
          return Boolean(value);
        }
        return true;
      })
      .matches(websiteRegex, {
        message: 'bds_website_invalid',
      }),
    ein: Yup.string()
      .matches(hasDigits, 'usoa_cd_ein_error')
      .max(9, 'cds_ein_length')
      .min(9, 'cds_ein_length'),
    ownershipPercentage: Yup.number()
      .required('css_primary_ownership')
      .min(0, 'css_min_primary_ownership')
      .max(100, 'css_max_primary_ownership'),
  });

export const companyDetailsOptionalSchema = (countries: CountryStateMap) =>
  Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .matches(validCompanyName, 'bfs_no_specials')
      .required('cds_company_name'),
    industry: Yup.string().trim(),
    incorporationYear: Yup.string()
      .matches(hasDigits, 'cds_year_of_corporation_digit')
      .length(4, 'cds_year_of_corporation_valid')
      .test('year', 'cds_year_of_corporation_range', (value) => {
        if (!value) return true;
        if (+value < 1920 || +value > new Date().getFullYear()) return false;
        return true;
      }),
    country: Yup.string(),
    physicalCountry: Yup.string(),
    street: Yup.string(),
    physicalStreet: Yup.string(),
    state: Yup.string().test(
      'state-match',
      'bds_accHolderState_match',
      function (value) {
        const { country } = this.parent; // Access the selected country from the form
        const validStates = countries[country] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }
    ),
    physicalState: Yup.string().test(
      'state-match',
      'bds_accHolderState_match',
      function (value) {
        const { physicalCountry } = this.parent; // Access the selected country from the form
        const validStates = countries[physicalCountry] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }
    ),
    city: Yup.string(),
    physicalCity: Yup.string(),
    zipcode: Yup.string()
      .max(20, 'bds_pincode_length')
      .test('countrySpecificRequired', 'sbes_pincode', (value, context) => {
        if (!value) return true;
        return validations.isPincodeRequired(value, context);
      })
      .test('countryFormat', 'abs_pincode', (value, context) => {
        if (!value) return true;
        return validations.isPincodeValid(value, context);
      }),
    physicalZipcode: Yup.string()
      .max(20, 'bds_pincode_length')
      .test('countrySpecificRequired', 'sbes_pincode', (value, context) => {
        if (!value) return true;
        return validations.isPincodeRequired(value, context);
      })
      .test('countryFormat', 'abs_pincode', (value, context) => {
        if (!value) return true;
        return validations.isPincodeValid(value, context);
      }),
    dba: Yup.string(),
    companyWebsite: Yup.string().trim().matches(websiteRegex, {
      message: 'bds_website_invalid',
    }),
    eCommerceWebsiteRef: Yup.boolean(),
    ecomSellerLink: Yup.string()
      .trim()
      .test('isRequired', 'e_comm_website_url', (value: any, context) => {
        if (context.parent.eCommerceWebsiteRef) {
          return Boolean(value);
        }
        return true;
      })
      .matches(websiteRegex, {
        message: 'bds_website_invalid',
      }),
    ein: Yup.string()
      .matches(hasDigits, 'usoa_cd_ein_error')
      .max(9, 'cds_ein_length')
      .min(9, 'cds_ein_length'),
    // ownershipPercentage: Yup.number().when('businessStructure', {
    //   is: BUSINESS_STRUCTURE.SOLE_PROPRIETOR,
    //   then: Yup.number(),
    //   otherwise: Yup.number()
    //     .min(0, 'css_min_primary_ownership')
    //     .max(100, 'css_max_primary_ownership'),
    // }),
    ownershipPercentage: Yup.number()
      .min(0, 'css_min_primary_ownership')
      .max(100, 'css_max_primary_ownership'),
  });

export const bankDetailsSchema = (countries: CountryStateMap) =>
  Yup.object().shape({
    bankName: Yup.string().required('bds_bankName'),
    abaNumber: Yup.string()
      .matches(/^[0-9]{9}$/, 'bds_fedABANumber_valid')
      .required('bds_fedABANumber'),
    bankAccountNumber: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'bds_accNumber_valid')
      .required('bds_accNumber'),
    currency: Yup.string().required('bds_bankCurrency'),
    country: Yup.string().required('bds_bankCountry'),
    address: Yup.string().required('bds_bankStreet'),
    state: Yup.string()
      .test('state-match', 'bds_accHolderState_match', function (value) {
        const { country } = this.parent; // Access the selected country from the form
        const validStates = countries[country] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      })
      .required('bds_bankState'),
    city: Yup.string().required('bds_bankCity'),
    // sameAsLocation: Yup.boolean(),
    accHolderName: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'bds_accHolderName_valid')
      .required('bds_accHolderName'),
    accHolderStreet: Yup.string().required('bds_accHolderStreet'),
    accHolderCountry: Yup.string().required('bds_accHolderCountry'),
    accHolderState: Yup.string()
      .test('state-match', 'bds_accHolderState_match', function (value) {
        const { accHolderCountry } = this.parent; // Access the selected country from the form
        const validStates = countries[accHolderCountry] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      })
      .required('bds_accHolderState'),
    accHolderCity: Yup.string().required('bds_accHolderCity'),
  });

export const bankDetailsOptionalSchema = (countries: CountryStateMap) =>
  Yup.object().shape({
    bankName: Yup.string(),
    abaNumber: Yup.string().matches(/^[0-9]{9}$/, 'bds_fedABANumber_valid'),
    bankAccountNumber: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'bds_accNumber'),
    currency: Yup.string(),
    country: Yup.string(),
    address: Yup.string(),
    state: Yup.string().test(
      'bank-state-match',
      'bds_accHolderState_match',
      function (value) {
        const { country } = this.parent; // Access the selected country from the form
        const validStates = countries[country] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }
    ),
    city: Yup.string(),
    // sameAsLocation: Yup.boolean(),
    accHolderName: Yup.string(),
    accHolderStreet: Yup.string(),
    accHolderCountry: Yup.string(),
    accHolderState: Yup.string().test(
      'acc-state-match',
      'bds_accHolderState_match',
      function (value) {
        const { accHolderCountry } = this.parent; // Access the selected country from the form
        const validStates = countries[accHolderCountry] || []; // Retrieve the list of valid states for the selected country
        if (validStates.length === 0) {
          return true;
        }
        if (value === '' || !value) {
          return true;
        }
        return validStates.includes(value); // Check if the selected state is in the list of valid states
      }
    ),
    accHolderCity: Yup.string(),
  });

export const legalEmailSchema = Yup.object().shape({
  email: Yup.string().trim().matches(emailRegex, {
    message: 'signUpSchema_valid_email',
  }),
});

export const legalDocumentDateSchema = Yup.object().shape({
  documentDate: Yup.mixed()
    .required('as_empty')
    .test('minDate', 'us_scf_legal_date_validation_min_date', (value) => {
      if (!value) return false;
      const currentDate = new Date();

      const yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(currentDate.getDate() - 1);

      return new Date(formatDateInput(value)) > yesterdayDate;
    }),
});
